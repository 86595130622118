import React, {Component} from "react";
import {observer} from "mobx-react";
import {hideModal, setAudioVolume} from "../js/functions";

import global from "./Global";

@observer
class Settings extends Component {

    constructor() {
        super();
        this.text = {
            interface: "Interface",
            music: "Musik",
            player: "Spieler",
            car: "Fahrzeuge",
            weapon: "Waffen"
        }
    }

    onChangeRange(element) {
        const {audio} = global;
        const name = element.getAttribute("data-setting");
        const value = Number(element.value);
        audio.settings[name] = value;
        setAudioVolume();
    }

    onCloseModal() {
        hideModal("settings");
    }

    componentDidMount() {
        hideModal("settings");
    }

    render() {
        const {audio} = global;

        return (
            <div id="settings-container" className="modal">
                <div className="content">
                    <h1>Einstellungen</h1>
                    {Object.keys(audio.settings).map((name, i) => {
                        return (
                            <div key={i} className="range-box">
                                <h3>{this.text[name]}</h3>
                                <input data-setting={name} type="range" min="0" max="100" step="1" value={audio.settings[name]} onChange={(e) => this.onChangeRange(e.target)}/>
                                <p>{audio.settings[name]}%</p>
                            </div>
                        );
                    })}
                    <button className="btn-modal-close btn-icon" onClick={() => this.onCloseModal()}><i className="far fa-xmark"></i></button>
                </div>
            </div>
        );
    }
}

export default Settings;