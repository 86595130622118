//############################################################################# Import

import io from "socket.io-client";

import global from "../components/Global";
import {px2pct, showModal, hideModal, createAudio, setAudioVolume} from "./functions";
import {initField, startGame, pauseGame, resetGame} from "./game";

let startingInterval;

//############################################################################# socket IO

const url = (process.env.NODE_ENV === "production") ? "https://mycoding-gangwars-f42ee4baa243.herokuapp.com/" : "http://localhost:3001";
const socket = io(url);

//############################################################################# Connect

socket.on("onConnect", (data) => {
    const {client, game} = global;

    client.id = socket.id;
    game.config = data.config;
    game.maps = data.maps;
    game.map = data.game.map;
    game.status = data.game.status;
    game.winner.counter = data.winner.counter;
    game.winner.id = data.winner.id;
    global.lobby = data.lobby;

    setSpritesheet();
    initField();

    if (game.status === "running" || game.status === "paused" || game.status === "evaluation") {
        startGame();
        hideModal("lobby");
    }
    if (game.status === "paused") showModal("pause");
    if (game.status === "evaluation") showModal("evaluation");
});

//############################################################################# Game / Lobby

socket.on("gameData", (lobbyData, items, weapons, cars, winner) => {
    const {game} = global;
    global.lobby = lobbyData;
    game.map.items = items;
    game.map.weapons = weapons;
    game.map.cars = cars;
    game.winner.counter = winner.counter;
    game.winner.id = winner.id;
});

socket.on("gameStatus", (gameStatus) => {
    const {game, lobby, client, lobbyStatusText} = global;
    const oldStatus = game.status;
    game.status = gameStatus;

    clearInterval(startingInterval);

    if (gameStatus === "lobby") {
        lobbyStatusText.current = lobbyStatusText.waiting;
    }

    if (gameStatus === "starting") {
        lobbyStatusText.current = lobbyStatusText.starting[0];
        let counter = 0;
        createAudio("interface", "counter_lobby");
        startingInterval = setInterval(() => {
            counter ++;
            lobbyStatusText.current = lobbyStatusText.starting[counter];
            if (counter >= lobbyStatusText.starting.length - 1) {
                clearInterval(startingInterval);
            } else {
                createAudio("interface", "counter_lobby");
            }
        }, 1000);
    }

    if (gameStatus === "running") startGame();
    if (gameStatus === "paused") pauseGame();
    if (gameStatus === "lobby") {
        if (!(client.id in lobby.player) && oldStatus !== "starting") {
            socket.emit("joinLobby", client.id);
        }
        resetGame();
    }

    if (gameStatus === "evaluation") {
        hideModal("pause");
        showModal("evaluation");
    }
});

socket.on("lobbyData", (lobbyData) => {
    global.lobby = lobbyData;
});

socket.on("map", (lobbyData, map) => {
    const {game} = global;
    global.lobby = lobbyData;
    game.map = map;
    setSpritesheet();
    initField();
});

//############################################################################# Audio

socket.on("audio", (category, name, volume) => {
    createAudio(category, name, true, volume);
});

//############################################################################# Effect

socket.on("effect", (type, name, pos, size, id) => {
    const {field, local} = global;

    const effectId = id || Math.random().toString().slice(2, 20);
    let effectSpritesheet = {};
    let imgSize = size || {width: px2pct(field.block), height: px2pct(field.block)};
    let center = false;

    if (type === "weapon") {
        if (name.includes("shot")) createAudio("weapon", name);
        if (name === "rpg_impact") {
            effectSpritesheet = {type: "once", row: 0, frames: 32, fps: 30, frame: 0, loopNumber: 0, width: 256, height: 256};
            imgSize = {width: px2pct(field.block * 2), height: px2pct(field.block * 2)};
            center = true;
            createAudio("weapon", name);
        }
        if (name === "mine_shot") {
            effectSpritesheet = {type: "loop", row: 0, frames: 4, fps: 7, frame: 0, loopNumber: 0, width: 256, height: 169};
        }
        if (name === "mine_impact") {
            effectSpritesheet = {type: "once", row: 0, frames: 32, fps: 30, frame: 0, loopNumber: 0, width: 256, height: 256};
            imgSize = {width: px2pct(field.block * 2), height: px2pct(field.block * 2)};
            center = true;
            createAudio("weapon", name);
        }
        if (name === "car_destroy") {
            effectSpritesheet = {type: "once", row: 0, frames: 32, fps: 30, frame: 0, loopNumber: 0, width: 256, height: 256};
            imgSize = {width: px2pct(field.block * 1.5), height: px2pct(field.block * 1.5)};
            center = true;
        }
    }
    local.effect[type][effectId] = {spritesheet: effectSpritesheet, name, pos, center, size: imgSize};
});

//############################################################################# Set Sprite Sheet

function setSpritesheet() {
    const {lobby, local, game} = global;
    local.car = {};
    local.player = {};
    //------------------------------------------------------ Player
    for (const playerId in lobby.player) {
        const player = lobby.player[playerId];
        if (!player) {
            delete local.player[playerId];
            continue;
        }
        if (!(playerId in local.player)) {
            const volume = setAudioVolume("player", "walk");
            local.player[playerId] = {
                spritesheet: {},
                audio: createAudio("player", "walk", false)
            }
            local.player[playerId].audio.volume = volume;
            local.player[playerId].spritesheet = {
                idle: {type: "loop", row: 0, frames: 10, fps: 20, frame: 0, loopNumber: 0},
                run: {type: "loop", row: 1, frames: 10, fps: 40, frame: 0, loopNumber: 0},
                die: {type: "once", row: 2, frames: 10, fps: 50, frame: 0, loopNumber: 0}
            }
        }
    }
    //------------------------------------------------------ Car
    for (const carName in game.map.cars) {
        const cars = game.map.cars[carName];
        for (const car of cars) {
            if (!car) {
                delete local.car[car.id];
                continue;
            }
            if (!(car.id in local.car)) {
                local.car[car.id] = {};
                if (carName === "police") {
                    const volume = setAudioVolume("car", `siren_${carName}`);
                    local.car[car.id].spritesheet = {
                        emergency: {type: "loop", row: 0, frames: 2, fps: 5, frame: 0, loopNumber: 0}
                    }
                    local.car[car.id].audio = createAudio("car", `siren_${carName}`, false);
                    local.car[car.id].audio.volume = volume;
                }
                if (carName === "ambulance") {
                    const volume = setAudioVolume("car", `siren_${carName}`);
                    local.car[car.id].spritesheet = {
                        emergency: {type: "loop", row: 0, frames: 2, fps: 5, frame: 0, loopNumber: 0}
                    }
                    local.car[car.id].audio = createAudio("car", `siren_${carName}`, false);
                    local.car[car.id].audio.volume = volume;
                }
            }
        }
    }
}

//############################################################################# Disconnect

socket.on("onDisconnect", (lobbyData) => {
    global.lobby = lobbyData;
    setSpritesheet();
});

//############################################################################# Export

export {socket, setSpritesheet};