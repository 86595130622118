//############################################################################# Import

import global from "../components/Global";
import {round} from "./functions";

//############################################################################# Render

function renderCanvas() {
    const {ctx, field, lobby, streetBlocks} = global;
    ctx.clearRect(0, 0, field.width, field.height);

    renderMap();
    
    global.playerCapture = {};
    for (const playerId in lobby.player) {
        const player = lobby.player[playerId];
        if (!player) continue;
        global.playerCapture[playerId] = round((player.capture.length * 100) / streetBlocks);
    }

    for (const playerId in lobby.player) {
        const player = lobby.player[playerId];
        if (!player) continue;
        renderShots(player);
        renderPlayers(player);
    }

    renderWeapons();
    renderItems();
    renderCars();
    renderEffects();
}

//############################################################################# Map

function renderMap() {
    const {ctx, field, devMode} = global;

    for (let i = 0; i < field.grid.length; i++) {
        const block = field.grid[i];

        if (block.type === "street") {
            ctx.drawImage(block.texture, block.coords[0], block.coords[2], block.coords[1] - block.coords[0], block.coords[3] - block.coords[2]);
        }

        if (block.type === "building") {
            if (block.texture && typeof block.texture === "object") {
                ctx.drawImage(block.texture.image, block.coords[0], block.coords[2], block.texture.width * field.block, block.texture.height * field.block);
            }
        }

        if (devMode) {
            // GRID
            ctx.beginPath();
            ctx.strokeStyle = "#444";
            ctx.lineWidth = 2;
            ctx.rect(block.coords[0], block.coords[2], block.coords[1] - block.coords[0], block.coords[3] - block.coords[2]);
            ctx.stroke();
        }
    }
}

//############################################################################# items

function renderItems() {
    const {ctx, field, game, texture} = global;

    for (const itemName in game.map.items) {
        const items = game.map.items[itemName];
        for (const item of items) {
            if (!item.available) continue;

            const block = field.grid[item.spawn.block.index];
            const configItem = game.config.item[itemName];
            const width = (configItem.width * field.block) / 100;
            const height = (configItem.height * field.block) / 100;

            ctx.beginPath();
            // ctx.fillStyle = "rgba(0, 200, 0, 0.3)";
            // ctx.fillRect(block.coords[0], block.coords[2], block.coords[1] - block.coords[0], block.coords[3] - block.coords[2]);
            
            ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
            ctx.arc(block.coords[0] + (field.block / 2), block.coords[2] + (field.block / 2), field.block / 2, 0, Math.PI * 2);
            ctx.fill();

            ctx.lineWidth = 2;
            // ctx.strokeStyle = "rgba(0, 200, 0, 0.7)";
            ctx.strokeStyle = configItem.color;
            ctx.arc(block.coords[0] + (field.block / 2), block.coords[2] + (field.block / 2), field.block / 2, 0, Math.PI * 2);
            ctx.stroke();

            ctx.drawImage(texture.item[itemName], (block.coords[0] + (field.block / 2)) - (width / 2), (block.coords[2] + (field.block / 2) - (height / 2)), width, height);
        }
    }
}

//############################################################################# Weapons

function renderWeapons() {
    const {ctx, field, game, texture} = global;

    for (const weaponName in game.map.weapons) {
        const weapons = game.map.weapons[weaponName];
        for (const weapon of weapons) {
            if (!weapon.available) continue;

            const block = field.grid[weapon.spawn.block.index];
            const configWeapon = game.config.weapon[weaponName];
            const width = (configWeapon.width * field.block) / 100;
            const height = (configWeapon.height * field.block) / 100;

            ctx.beginPath();
            // ctx.fillStyle = "rgba(0, 200, 0, 0.3)";
            // ctx.fillRect(block.coords[0], block.coords[2], block.coords[1] - block.coords[0], block.coords[3] - block.coords[2]);
            
            ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
            ctx.arc(block.coords[0] + (field.block / 2), block.coords[2] + (field.block / 2), field.block / 2, 0, Math.PI * 2);
            ctx.fill();

            ctx.lineWidth = 2;
            // ctx.strokeStyle = "rgba(0, 200, 0, 0.7)";
            ctx.strokeStyle = configWeapon.color;
            ctx.arc(block.coords[0] + (field.block / 2), block.coords[2] + (field.block / 2), field.block / 2, 0, Math.PI * 2);
            ctx.stroke();

            ctx.drawImage(texture.weapon[weaponName], (block.coords[0] + (field.block / 2)) - (width / 2), (block.coords[2] + (field.block / 2) - (height / 2)), width, height);
        }
    }
}

//############################################################################# Cars

function renderCars() {
    const {ctx, field, game, texture, local} = global;

    for (const carName in game.map.cars) {
        const cars = game.map.cars[carName];
        for (const car of cars) {
            if (!car.available) {
                if (local.car[car.id]?.audio && !local.car[car.id].audio.paused) local.car[car.id].audio.pause();
                continue;
            }

            if (local.car[car.id]?.audio && local.car[car.id].audio.paused) local.car[car.id].audio.play();

            const posX = (car.pos.x_percent * field.width) / 100;
            const posY = (car.pos.y_percent * field.height) / 100;
            const width = (car.width * field.block) / 100;
            const height = (car.height * field.block) / 100;
            
            let animation = "emergency";
            let spriteSheetRow = {type: "once", row: 0, frames: 1, fps: 1, frame: 0, loopNumber: 0};
            if (local.car[car.id].spritesheet) spriteSheetRow = local.car[car.id].spritesheet[animation];

            ctx.save();

            ctx.translate(posX + (width / 2), posY + (height / 2));
            ctx.rotate(car.degree * Math.PI / 180);

            if (local.car[car.id].spritesheet) {
                //------------------------------------------------------ Spritesheet
                controlSpritesheet(spriteSheetRow);

                if (car.drive === "left" || car.drive === "right") {
                    ctx.translate(-posX - (width / 2), -posY - (height / 2));
                    ctx.drawImage(
                        texture.car[carName],
                        256 * spriteSheetRow.frame, 256 * spriteSheetRow.row, 256, 115,
                        posX, posY, width, height
                    );
                } else {
                    ctx.translate(-posX - (height / 2), -posY - (width / 2));
                    ctx.drawImage(
                        texture.car[carName],
                        256 * spriteSheetRow.frame, 256 * spriteSheetRow.row, 256, 115,
                        posX, posY, height, width
                    );
                }
            } else {
                //------------------------------------------------------ Image
                if (car.drive === "left" || car.drive === "right") {
                    ctx.translate(-posX - (width / 2), -posY - (height / 2));
                    ctx.drawImage(texture.car[carName],posX, posY, width, height);
                } else {
                    ctx.translate(-posX - (height / 2), -posY - (width / 2));
                    ctx.drawImage(texture.car[carName],posX, posY, height, width);
                }
            }

            ctx.restore();

            // ctx.fillStyle = "rgba(255, 0, 0, 0.2)";
            // ctx.fillRect(
            //     posX, posY, width, height
            // );
        }
    }
}

//############################################################################# Players

function renderPlayers(player) {
    const {ctx, field, game, texture, local, playerColors} = global;

    const posX = (player.pos.x_percent * field.width) / 100;
    const posY = (player.pos.y_percent * field.height) / 100;
    const width = (game.config.player.width * field.block) / 100;
    const height = (game.config.player.height * field.block) / 100;
    //------------------------------------------------------ Capture
    for (const blockIndex of player.capture) {
        const coords = field.grid[blockIndex].coords;
        ctx.beginPath();
        ctx.fillStyle = playerColors[player.color].capture;
        // ctx.fillRect(coords[0], coords[2], field.block, field.block);
        ctx.fillRect(coords[0] + (field.block / 4), coords[2] + (field.block / 4), field.block / 2, field.block / 2);
    }
    // Block
    // ctx.beginPath();
    // ctx.fillStyle = "rgba(0, 255, 0, 0.4)";
    // ctx.fillRect(field.grid[player.pos.block].coords[0], field.grid[player.pos.block].coords[2], field.block, field.block);
    //------------------------------------------------------ Player
    let animation = "idle";
    if (player.moving !== "") animation = "run";
    if (player.health <= 0) animation = "die";

    let spriteSheetRow = {type: "loop", row: 0, frames: 10, fps: 20, frame: 0, loopNumber: 0};
    if (local.player[player.id]?.spritesheet) {
        spriteSheetRow = local.player[player.id].spritesheet[animation];
        if (animation === "idle" && local.player[player.id].spritesheet.die.frame > 0) {
            local.player[player.id].spritesheet.die.frame = 0;
        }
    }

    controlSpritesheet(spriteSheetRow);

    const calcWidth = (320 * width) / 100;
    const calcHeight = (120 * height) / 100;

    ctx.drawImage(
        texture.player[player.color][player.view],
        19 + (256 * spriteSheetRow.frame), 99 + (256 * spriteSheetRow.row), 218, 130,
        posX - (calcWidth / 2) + (width / 2), posY - (calcHeight / 2) + (height / 2) + 4, calcWidth, calcHeight
    );

    if (local.player[player.id]?.audio) {
        if (player.moving !== "" && player.health > 0) {
            if (local.player[player.id].audio.paused) local.player[player.id].audio.play();
        } else {
            if (!local.player[player.id].audio.paused) local.player[player.id].audio.pause();
        }
    }
}

//############################################################################# Shots

function renderShots(player) {
    const {ctx, field, texture} = global;

    for (const projectile of player.shots) {
        const posX = (projectile.pos.x_percent * field.width) / 100;
        const posY = (projectile.pos.y_percent * field.height) / 100;
        const width = (projectile.width * field.block) / 100;
        const height = (projectile.height * field.block) / 100;

        ctx.beginPath();
        ctx.fillStyle = projectile.style;
        if (projectile.type === "rect") {
            ctx.fillRect(posX, posY, width, height);
        }
        if (projectile.type === "circle") {
            ctx.arc(posX + (width / 2), posY + (width / 2), width / 2, 0, Math.PI * 2);
            ctx.fill();
        }
        if (projectile.type === "image") {
            ctx.drawImage(texture.weapon[projectile.style], posX, posY, width, height);
        }
    }
}

//############################################################################# Effects

function renderEffects() {
    const {ctx, field, texture, local} = global;

    for (const type in local.effect) {
        const category = local.effect[type];
        for (const id in category) {
            const effect = category[id];

            if (effect.spritesheet) {
                controlSpritesheet(effect.spritesheet);

                const posX = (effect.pos.x_percent * field.width) / 100;
                const posY = (effect.pos.y_percent * field.height) / 100;
                const width = (effect.size.width * field.block) / 100;
                const height = (effect.size.height * field.block) / 100;
            
                ctx.drawImage(
                    texture.effect[type][effect.name],
                    effect.spritesheet.width * effect.spritesheet.frame, effect.spritesheet.height * effect.spritesheet.row, effect.spritesheet.width, effect.spritesheet.height,
                    (effect.center) ? posX - (width / 2) : posX, (effect.center) ? posY - (height / 2) : posY, width, height
                );

                if (effect.spritesheet.type === "once" && effect.spritesheet.frame === effect.spritesheet.frames - 1) {
                    delete local.effect[type][id];
                }
            }

        }
    }
}

//############################################################################# Control Spritesheet

function controlSpritesheet(row) {
    const {frame} = global;

    if (frame.interval * row.loopNumber > 1000 / row.fps) {
        row.loopNumber = 0;
        if (row.type === "loop") row.frame ++;
        if (row.type === "once" && row.frame < row.frames - 1) row.frame ++;
        if (row.frame >= row.frames) row.frame = 0;
    } else {
        row.loopNumber ++;
    }
}

//############################################################################# Export

export {renderCanvas, renderMap};