import React, {Component} from "react";
import {observer} from "mobx-react";
import {socket} from "../js/online";
import {hideModal, createAudio, setAudioVolume} from "../js/functions";
import {setGlobalEvents} from "../js/input";

import global from "./Global";

@observer
class Map extends Component {

    onMapChange(number) {
        createAudio("interface", "click");
        socket.emit("map", number);
    }

    onCloseModal() {
        hideModal("map");
    }

    componentDidMount() {
        hideModal("map");
        setGlobalEvents();
        setAudioVolume();
    }

    render() {
        const {lobby, game, assetPath} = global;

        return (
            <div id="map-container" className="modal">
                <div className="content">
                    <h1>Map</h1>
                    <div id="maps">{
                        game.maps.map((map, i) => {
                            return (
                                <div className={`map${(i === lobby.map) ? " selected" : ""}`} onClick={() => this.onMapChange(i)} key={i}>
                                    <div className="flex-row">
                                        <h1>{map.size[0]} x {map.size[1]}</h1>
                                        <p>{map.player.length}</p>
                                    </div>
                                    <img src={`${assetPath}/images/map/${i + 1}.jpg`} alt=""/>
                                    <span><i className="far fa-check"></i></span>
                                </div>
                            );
                        })
                    }</div>
                    <button className="btn-modal-close btn-icon" onClick={() => this.onCloseModal()}><i className="far fa-xmark"></i></button>
                </div>
            </div>
        );
    }
}

export default Map;