import React from "react";
import ReactDOM from "react-dom/client";

import Field from "./components/Field";
import Evaluation from "./components/Evaluation";
import Pause from "./components/Pause";
import Lobby from "./components/Lobby";
import Settings from "./components/Settings";
import Map from "./components/Map";
import Fullscreen from "./components/Fullscreen";

import "./fontawesome/pro6/css/all.min.css";
import "./style.css";

const root = ReactDOM.createRoot(
    document.getElementById("root")
);

root.render(
    <>
        <Field/>
        <Evaluation/>
        <Pause/>
        <Lobby/>
        <Settings/>
        <Map/>
        <Fullscreen/>
    </>
);