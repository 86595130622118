import React, {Component} from "react";
import {observer} from "mobx-react";
import {socket} from "../js/online";
import {showModal, hideModal} from "../js/functions";

import global from "./Global";

@observer
class Pause extends Component {

    componentDidMount() {
        hideModal("pause");
    }

    render() {
        const {lobby, client} = global;

        return (
            <div id="pause-container" className="modal">
                <div className="content">
                    <h1 className="waiting">Pause</h1>
                    <div className="flex-row">
                        <button disabled={!(client.id in lobby.player)} onClick={() => socket.emit("gameStatus", "running")}>
                            <span><i className="far fa-arrow-rotate-left"></i>Weiterspielen</span>
                        </button>
                        <button className="btn-icon-big" onClick={() => showModal("settings")}>
                            <i className="far fa-cog"></i>
                        </button>
                    </div>
                    <div className="flex-row">
                        <button className="btn-red" disabled={!(client.id in lobby.player)} onClick={() => socket.emit("gameStatus", "lobby")}>
                            <span><i className="far fa-xmark"></i>Spiel abbrechen</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pause;