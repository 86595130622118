import React, {Component, createRef} from "react";
import {observer} from "mobx-react";
import {socket} from "../js/online";
import {renderMap} from "../js/render";
import {enterFullscreen, exitFullscreen, isFullscreen} from "../js/functions";

import global from "./Global";

import "../js/online";
import "../js/input";

//############################################################################# Initalize

const {texture, assetPath} = global;

for (const categoryName in texture) {
    const category = texture[categoryName];
    if (Array.isArray(category)) {
        const imageObject = {};
        for (let imageName of category) {
            const img = new Image();
            img.src = `${assetPath}/images/${categoryName}/${imageName}`;
            img.onload = renderMap;
            imageName = imageName.replace(/\..+$/, "");
            imageObject[imageName] = img;
        }
        texture[categoryName] = imageObject;
    } else {
        for (const subCategoryName in category) {
            const subCategory = category[subCategoryName];
            const imageObject = {};
            for (let imageName of subCategory) {
                const img = new Image();
                img.src = `${assetPath}/images/${categoryName}/${subCategoryName}/${imageName}`;
                img.onload = renderMap;
                imageName = imageName.replace(/\..+$/, "");
                imageObject[imageName] = img;
            }
            texture[categoryName][subCategoryName] = imageObject;
        }
    }
}

//############################################################################# Component

@observer
class Field extends Component {

    constructor() {
        super();
        this.canvas = createRef();
    }

    toggleFullscreen() {
        if (isFullscreen()) {
            exitFullscreen();
        } else {
            enterFullscreen();
        }
    }

    componentDidMount() {
        global.ctx = this.canvas.current.getContext("2d");
    }

    render() {
        const {lobby, game, field, assetPath, playerCapture, playerColors, client, devMode} = global;

        const mapX = [];
        const mapY = [];
        if (game.map?.size) {
            for (let i = 0; i < game.map.size[0]; i++) mapX.push(i);
            for (let i = 0; i < game.map.size[1]; i++) mapY.push(i);
        }

        return (
            <div id="field-container" onContextMenu={(e) => e.preventDefault()}>
                <div id="field-header" style={{ width: `${field.width}px`}}>
                    <div id="capture">
                        <div id="capture-bar">{
                        Object.values(lobby.player).map((player, i) => {
                            const captureBarPercent = Object.values(playerCapture).reduce((a, b) => a + b, 0);
                            const percentFromBar = (playerCapture[player.id] * 100) / captureBarPercent;
                            return (<span key={i} data-percent={playerCapture[player.id]?.toString() || "0"} style={{ width: `${percentFromBar}%`, backgroundColor: playerColors[player.color].main }}></span>);
                        })
                        }</div>
                    </div>
                    <div id="player-box-container">{
                        Object.values(lobby.player).map((player, i) => {
                            const showWeapon = player.weapon && player.id === client.id;
                            const imageSrc = (showWeapon) ? `${assetPath}/images/weapon/${player.weapon.name}.png` : `${assetPath}/images/player/${player.color}/player.png`;
                            return (
                                <div className="player-box" key={i}>
                                    <div className="weapon">
                                        <div className="weapon-image">
                                            <span style={{ height: `${(showWeapon) ? player.weapon.reloading : 0}%` }}></span>
                                            <img src={imageSrc} alt=""/>
                                        </div>
                                        <div className="weapon-capacity">{(showWeapon) ? player.weapon.capacity : ""}</div>
                                    </div>
                                    <div className="flex-col">
                                        <div className="name">{player.name || "..."}</div>
                                        <div className={"health" + ((player.health <= 66) ? (player.health <= 33) ? " critical" : " low" : "")}>
                                            <p>{player.health} %</p>
                                            <span style={{ width: `${player.health}%` }}></span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }</div>
                    <div id="actions">
                        <div className="flex-row">
                            {
                                (game.winner.id && game.winner.id in lobby.player) ?
                                    <img src={`${assetPath}/images/player/${lobby.player[game.winner.id].color}/player.png`} alt=""/>
                                    : ""
                            }                            
                            <h1>{(game.winner.counter !== null) ? game.winner.counter : ""}</h1>
                        </div>
                        <div className="flex-col">
                            <button className="btn-icon-small" disabled={!(client.id in lobby.player)} onClick={() => socket.emit("gameStatus", "paused")}>
                                <i className="far fa-pause"></i>
                            </button>
                            <button className="btn-icon-small" onClick={() => this.toggleFullscreen()}>
                                <i className={(isFullscreen()) ? "far fa-compress" : "far fa-expand"}></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div id="field" style={{ width: field.width, height: field.height, overflow: (devMode) ? "visible" : "hidden"}}>
                    {(devMode) ? (
                        <div id="grid-text">
                            <div id="grid-x">{ mapX.map((x, i) => <span key={i} style={{ width: field.block }}>{i}</span>) }</div>
                            <div id="grid-y">{ mapY.map((x, i) => <span key={i} style={{ height: field.block }}>{i}</span>) }</div>
                            {
                                field.grid.map((x, i) => {
                                    return (
                                        <span key={i} style={{ width: field.block, height: field.block }}>{i}</span>
                                    );
                                })
                            }
                        </div>
                    ) : ""}
                    <canvas ref={this.canvas} width={field.width} height={field.height}></canvas>
                </div>
            </div>
        );
    }
}

export default Field;