import React, {Component} from "react";
import {observer} from "mobx-react";
import {hideModal, enterFullscreen, startMusic} from "../js/functions";

@observer
class Pause extends Component {
    
    initGameStart() {
        enterFullscreen();
        hideModal("fullscreen");
        startMusic("lobby");
    }

    render() {
        return (
            <div id="fullscreen-container" className="modal">
                <div className="content">
                    <h1>Vollbild?</h1>
                    <div className="flex-row">
                        <button onClick={() => this.initGameStart()}>
                            <span><i className="far fa-check"></i>Ja</span>
                        </button>
                        <button className="btn-red" onClick={() => {
                            hideModal("fullscreen");
                            startMusic("lobby");
                        }}>
                            <span><i className="far fa-xmark"></i>Nein</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pause;