import React, {Component, createRef} from "react";
import {observer} from "mobx-react";
import {socket} from "../js/online";
import {showModal, enterFullscreen, exitFullscreen, isFullscreen, createAudio} from "../js/functions";

import global from "./Global";

@observer
class Lobby extends Component {

    constructor() {
        super();
        this.playerName = createRef();
    }

    // changePlayerColor(color) {
    //     const {client} = global;
    //     const el = this.playerImage.current;

    //     const tl = gsap.timeline();
    //     tl.to(el, {filter: "blur(0px)", duration: 0});
    //     tl.to(el, {filter: "blur(5px)", duration: 0.2, onComplete: () => {
    //             socket.emit("playerData", client.id, "color", color);
    //         }
    //     });
    //     tl.to(el, {filter: "blur(0px)", duration: 0.2});
    // }

    openMapWindow() {
        showModal("map");
    }

    toggleFullscreen() {
        if (isFullscreen()) {
            exitFullscreen();
        } else {
            enterFullscreen();
        }
    }

    changePlayerColor(color) {
        const {client} = global;
        socket.emit("playerData", client.id, "color", color)
        createAudio("interface", "color");
    }

    componentDidMount() {
        this.playerName.current.focus();
    }

    render() {
        const {lobby, game, client, assetPath, playerColors, lobbyStatusText} = global;

        const playerClient = lobby.player[client.id] || null;
        const playerReady = playerClient?.ready;
        const disabled = (game.status === "lobby" || game.status === "starting") ? false : true;

        return (
            <div id="lobby-container" className="modal">
                <div className="content">
                    <div className="flex-row">
                        <label>
                            <input 
                                ref={this.playerName}
                                type="text"
                                maxLength="20"
                                placeholder="Spielername ..."
                                autoComplete="off"
                                disabled={disabled || playerClient?.ready}
                                onChange={(e) => socket.emit("playerData", client.id, "name", e.target.value)}
                            />
                        </label>
                        <div id="player-colors">{
                            Object.keys(playerColors).map((colorName, i) => {
                                const color = playerColors[colorName].main;
                                const clientColor = lobby.player[client.id]?.color || "";
                                const usedColors = Object.values(lobby.player).map(x => x.color);
                                return (
                                    <label key={i}>
                                        <input 
                                            name="player-color"
                                            type="radio"
                                            value={colorName}
                                            checked={clientColor === colorName}
                                            disabled={usedColors.includes(colorName) && clientColor !== colorName}
                                            onChange={(e) => this.changePlayerColor(e.target.value)}
                                        />
                                        <i className="fas fa-palette" style={{ color }}></i>
                                    </label>
                                );
                            })
                        }</div>
                    </div>
                    <div id="player-preview">{
                        Object.values(lobby.player).map((player, i) => {
                            return (
                                <div className="player" key={i}>
                                    <h1>{player.name || "..."}</h1>
                                    <img src={`${assetPath}/images/player/${player.color}/player.png`} alt=""/>
                                    <span className={(player.ready) ? "ready" : ""}></span>
                                </div>
                            );
                        })
                    }</div>
                    <div id="lobby-settings">
                        <div className="flex-col">
                            <p id="lobby-counter" className={(game.status === "lobby") ? "waiting" : ""}>{lobbyStatusText.current}</p>
                        </div>
                        <button 
                            className={(playerReady) ? "btn-red" : ""}
                            // disabled={(disabled) ? true : (playerClient) ? playerClient.name.length < 0 : true}
                            disabled={(disabled) ? true : (playerClient) ? playerClient.name.length < 2 : true}
                            onClick={() => socket.emit("playerData", client.id, "ready", !playerReady)}
                        >
                            <span><i className={(playerReady) ? "far fa-xmark" : "far fa-check"}></i>{(playerReady) ? "Nicht bereit" : "Bereit"}</span>
                        </button>
                        <div className="map">
                            <div className="flex-row">
                                <h1>{game.map?.size[0]} x {game.map?.size[1]}</h1>
                                <p>{game.map?.player.length}</p>
                            </div>
                            <img src={`${assetPath}/images/map/${lobby.map + 1}.jpg`} alt=""/>
                            <div className="flex-row">
                                <button className="btn-icon" onClick={() => showModal("settings")}>
                                    <i className="far fa-cog"></i>
                                </button>
                                <button className="btn-slim" disabled={disabled} onClick={() => this.openMapWindow()}>
                                    <span><i className="far fa-arrow-right-arrow-left"></i>Map</span>
                                </button>
                                <button className="btn-icon" onClick={() => this.toggleFullscreen()}>
                                    <i className={(isFullscreen()) ? "far fa-compress" : "far fa-expand"}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Lobby;