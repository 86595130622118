//############################################################################# Import & Variables

import global from "../components/Global";

import {socket} from "./online";
import {initField} from "./game";
import {createAudio} from "./functions";

const inputKeys = [];

const controls = {
    move: {
        left: ["a", "ArrowLeft","f"],
        right: ["d", "ArrowRight","h"],
        up: ["w", "ArrowUp","t"],
        down: ["s", "ArrowDown","g"],
    },
    weapon: {
        shoot: [" ", "Enter"]
    }
}

const moveControls = [];
for (const category in controls.move) moveControls.push(...controls.move[category]);

//############################################################################# Global

function setGlobalEvents() {
    const buttons = document.querySelectorAll("button");
    
    for (const el of buttons) {
        el.addEventListener("mousedown", () => {
            createAudio("interface", "click");
        });
    }
}

window.ondragstart = function() {
    return false;
}

window.onresize = () => {
    initField();
}

//############################################################################# Controls

document.addEventListener("keydown", (e) => {
    if (e.target.tagName !== "INPUT") e.preventDefault();
    if (!inputKeys.includes(e.key)) {
        inputKeys.unshift(e.key);
        checkInput();
    }
});

document.addEventListener("keyup", (e) => {
    if (e.target.tagName !== "INPUT") e.preventDefault();
    inputKeys.splice(inputKeys.indexOf(e.key), 1);
    checkInput();
});

function checkInput() {
    const {client} = global;
    //------------------------------------------------------ Player Move
    if (controls.move.left.includes(inputKeys.filter(x => moveControls.includes(x))[0])) {
        socket.emit("playerMoving", client.id, "left");
    } else
    if (controls.move.right.includes(inputKeys.filter(x => moveControls.includes(x))[0])) {
        socket.emit("playerMoving", client.id, "right");
    } else
    if (controls.move.up.includes(inputKeys.filter(x => moveControls.includes(x))[0])) {
        socket.emit("playerMoving", client.id, "up");
    } else
    if (controls.move.down.includes(inputKeys.filter(x => moveControls.includes(x))[0])) {
        socket.emit("playerMoving", client.id, "down");
    } else
    if (inputKeys.filter(x => moveControls.includes(x)).length === 0) {
        socket.emit("playerMoving", client.id, "");
    }
    //------------------------------------------------------ Shot
    const activeShot = controls.weapon.shoot.includes(inputKeys.filter(x => controls.weapon.shoot.includes(x))[0]);
    socket.emit("shot", client.id, activeShot);
}

//############################################################################# Export

export {setGlobalEvents};