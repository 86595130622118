import {makeAutoObservable, configure} from "mobx";

// beide spieler tot -> kein spielende
// Waffe spawn immernoch oben links (evtl blast radius)
// farben ingame anpassen

configure({
    enforceActions: "never"
});

class global {
    
    devMode = false;
    assetPath = ".";
    ctx = null;
    playerCapture = {};
    streetBlocks = 0;
    lobbyStatusText = {
        current: "Warte auf Spieler ...",
        waiting: "Warte auf Spieler ...",
        starting: [
            "Start in 3",
            "Start in 2",
            "Start in 1",
            "Start in 0"
        ]
    }
    
    client = {
        id: null
    }

    game = {
        config: null,
        status: "lobby",
        map: null,
        maps: [],
        frame: null,
        winner: {
            id: null,
            counter: null
        }
    }

    frame = {
        lastTime: 0,
        interval: 0,
        number: 0
    }

    field = {
        width: 0,
        height: 0,
        block: 0,
        grid: [],
        gridCoords: {
            x: [],
            y: []
        }
    }

    lobby = {
        player: {}
    }

    local = {
        player: {},
        car: {},
        effect: {
            weapon: {}
        }
    }

    music = {
        lobby: null,
        game: null
    }

    playerColors = {
        green: {
            main: "rgb(38, 157, 14)",
            capture: "rgba(38, 157, 14, 0.6)"
        },
        blue: {
            main: "rgb(12, 85, 163)",
            capture: "rgba(12, 85, 163, 0.5)"
        },
        red: {
            main: "rgb(198, 29, 29)",
            capture: "rgba(198, 29, 29, 0.25)"
        },
        yellow: {
            main: "rgb(226, 223, 14)",
            capture: "rgba(216, 203, 14, 0.4)"
        },
        purple: {
            main: "rgb(155, 28, 243)",
            capture: "rgba(155, 28, 243, 0.5)"
        },
        orange: {
            main: "rgb(239, 124, 23)",
            capture: "rgba(239, 124, 23, 0.25)"
        },
        turquoise: {
            main: "rgb(24, 202, 184)",
            capture: "rgba(24, 202, 184, 0.25)"
        },
        pink: {
            main: "rgb(200, 27, 197)",
            capture: "rgba(200, 27, 197, 0.22)"
        }
    }

    texture = {
        building: {
            x: ["1x1_1.jpg", "2x1_1.jpg", "2x1_2.jpg", "2x2_1.jpg", "2x2_2.jpg", "2x2_3.jpg", "3x1_1.jpg", "3x1_2.jpg", "3x2_1.jpg", "3x2_2.jpg", "3x2_3.jpg", "3x2_4.jpg", "3x3_1.jpg", "3x3_2.jpg", "4x1_1.jpg", "4x1_2.jpg", "4x2_1.jpg", "4x2_2.jpg", "4x2_3.jpg", "4x3_1.jpg", "5x3_1.jpg", "5x4_1.jpg", "6x4_1.jpg", "6x4_2.jpg"],
            y: ["1x1_1.jpg", "2x1_1.jpg", "2x1_2.jpg", "2x2_1.jpg", "2x2_2.jpg", "2x2_3.jpg", "3x1_1.jpg", "3x1_2.jpg", "3x2_1.jpg", "3x2_2.jpg", "3x2_3.jpg", "3x2_4.jpg", "3x3_1.jpg", "3x3_2.jpg", "4x1_1.jpg", "4x1_2.jpg", "4x2_1.jpg", "4x2_2.jpg", "4x2_3.jpg", "4x3_1.jpg", "5x3_1.jpg", "5x4_1.jpg", "6x4_1.jpg", "6x4_2.jpg"],
        },
        street: [
            "horizontal.jpg",
            "vertical.jpg",
            "crossing.jpg",
            "crossing_up.jpg",
            "crossing_down.jpg",
            "crossing_left.jpg",
            "crossing_right.jpg",
            "corner_top_left.jpg",
            "corner_top_right.jpg",
            "corner_bottom_left.jpg",
            "corner_bottom_right.jpg",
            "deadend_left.jpg",
            "deadend_right.jpg",
            "deadend_up.jpg",
            "deadend_down.jpg"
        ],
        item: ["health.png"],
        weapon: ["pistol.png", "rifle.png", "sniper.png", "rpg.png", "mine.png"],
        player: {
            green: ["player.png", "left.png", "right.png", "up.png", "down.png", "idle.png"],
            blue: ["player.png", "left.png", "right.png", "up.png", "down.png", "idle.png"],
            red: ["player.png", "left.png", "right.png", "up.png", "down.png", "idle.png"],
            yellow: ["player.png", "left.png", "right.png", "up.png", "down.png", "idle.png"],
            purple: ["player.png", "left.png", "right.png", "up.png", "down.png", "idle.png"],
            orange: ["player.png", "left.png", "right.png", "up.png", "down.png", "idle.png"],
            turquoise: ["player.png", "left.png", "right.png", "up.png", "down.png", "idle.png"],
            pink: ["player.png", "left.png", "right.png", "up.png", "down.png", "idle.png"],
        },
        car: ["police.png", "ambulance.png", "normal.png", "sports.png", "pickup.png"],
        effect: {
            weapon: ["rpg_impact.png", "mine_shot.png", "mine_impact.png", "car_destroy.png"]
        }
    }

    audio = {
        settings: {
            interface: 50,
            music: 50,
            player: 50,
            car: 50,
            weapon: 50
        },
        player: {
            walk: {number: 1, volume: 0.08, maxVolume: 0.08, loop: true},
            revive: {number: 1, volume: 0.2, maxVolume: 0.2, loop: false},
            hit: {number: 3, volume: 0.4, maxVolume: 0.4, loop: false},
            hit_car_death: {number: 1, volume: 0.4, maxVolume: 0.4, loop: false},
        },
        item: {
            health: {number: 1, volume: 0.2, maxVolume: 0.2, loop: false},
        },
        weapon: {
            equip: {number: 1, volume: 1.0, maxVolume: 1.0, loop: false},
            empty: {number: 1, volume: 0.8, maxVolume: 0.8, loop: false},
            pistol_shot: {number: 1, volume: 0.9, maxVolume: 0.9, loop: false},
            rifle_shot: {number: 1, volume: 0.9, maxVolume: 0.9, loop: false},
            sniper_shot: {number: 1, volume: 0.7, maxVolume: 0.7, loop: false},
            rpg_shot: {number: 1, volume: 0.3, maxVolume: 0.3, loop: false},
            rpg_impact: {number: 1, volume: 0.6, maxVolume: 0.6, loop: false},
            mine_shot: {number: 1, volume: 0.5, maxVolume: 0.5, loop: false},
            mine_impact: {number: 1, volume: 0.6, maxVolume: 0.6, loop: false},
        },
        car: {
            hit: {number: 4, volume: 0.5, maxVolume: 0.5, loop: false},
            destroyed: {number: 1, volume: 0.6, maxVolume: 0.6, loop: false},
            siren_police: {number: 1, volume: 0.02, maxVolume: 0.02, loop: true},
            siren_ambulance: {number: 1, volume: 0.02, maxVolume: 0.02, loop: true},
            horn_normal: {number: 1, volume: 0.2, maxVolume: 0.2, loop: false},
            horn_sports: {number: 1, volume: 0.2, maxVolume: 0.2, loop: false},
            horn_pickup: {number: 1, volume: 0.2, maxVolume: 0.2, loop: false},
        },
        interface: {
            counter: {number: 1, volume: 0.4, maxVolume: 0.4, loop: false},
            counter_end: {number: 1, volume: 0.5, maxVolume: 0.5, loop: false},
            counter_start: {number: 1, volume: 0.4, maxVolume: 0.4, loop: false},
            counter_stop: {number: 1, volume: 0.8, maxVolume: 0.8, loop: false},
            counter_lobby: {number: 1, volume: 0.5, maxVolume: 0.5, loop: false},
            click: {number: 1, volume: 0.4, maxVolume: 0.4, loop: false},
            color: {number: 1, volume: 0.4, maxVolume: 0.4, loop: false},
            unready: {number: 1, volume: 0.4, maxVolume: 0.4, loop: false},
        },
        music: {
            lobby: {number: 1, volume: 0.07, maxVolume: 0.07, loop: true},
            game: {number: 1, volume: 0.07, maxVolume: 0.07, loop: true},
        }
    }

    constructor() {
        makeAutoObservable(this);
    }
}

const data = new global();
// const data = window.data = new global();

//############################################################################# Music

const {audio, music, assetPath} = data;

music.lobby = new Audio(`${assetPath}/audio/music/lobby1.ogg`);
music.lobby.volume = audio.music.lobby.volume;
music.lobby.loop = true;

music.game = new Audio(`${assetPath}/audio/music/game1.ogg`);
music.game.volume = audio.music.game.volume;
music.game.loop = true;

//############################################################################# Export

export default data;