//############################################################################# Import

import {gsap} from "gsap";
import {setSpritesheet} from "./online";

import global from "../components/Global";

//############################################################################# Round

function round(number = 0, decimals = 0) {
    let roundedNumber = Math.round((number + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals);
    if (Number.isNaN(roundedNumber)) roundedNumber = 0;
    return roundedNumber;
}

//############################################################################# Random

function random(min = 0, max = 10) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

//############################################################################# Pixel / Percent

function px2pct(a, b = global.field.block) {
    return (a * 100) / b;
}

function pct2px(a, b = global.field.block) {
    return (a * b) / 100;
}

//############################################################################# Show / Hide Modal

function showModal(name = "") {
    const element = document.querySelector(`#${name}-container`);
    if (!element) return;
    gsap.to(element, 0.5, {
        opacity: 1,
        display: "flex",
        ease: "power1.out"
    });
    gsap.to(element.querySelector(".content"), 0.5, {
        y: "0vh",
        display: "flex",
        ease: "back.out(1.2)"
    });
}

function hideModal(name = "") {
    const element = document.querySelector(`#${name}-container`);
    if (!element) return;
    gsap.to(element, 0.5, {
        opacity: 0,
        display: "none",
        ease: "power1.in"
    });
    gsap.to(element.querySelector(".content"), 0.5, {
        y: "-100vh",
        display: "none",
        ease: "back.in(1.2)"
    });
}

//############################################################################# Create Audio

function createAudio(category, name, play = true, volumePercent) {
    const {audio, assetPath} = global;
    const randomNumber = random(1, audio[category][name].number);
    let audioFile = new Audio(`${assetPath}/audio/${category}/${name}${randomNumber}.ogg`);

    if (volumePercent !== undefined) {
        const maxVolume = setAudioVolume(category, name);
        let volume = (maxVolume * volumePercent) / 100;
        if (volume < 0.08) volume = 0.08;
        if (volume > 1) volume = 1;
        audioFile.volume = volume;
    } else {
        audioFile.volume = audio[category][name].volume;
    }

    audioFile.loop = audio[category][name].loop;
    if (play) audioFile.play();
    return audioFile;
}

//############################################################################# Set Audio Volume

function setAudioVolume(specCategory, specName) {
    const {audio, music, game} = global;

    if (specCategory && specName) {
        const item = audio[specCategory][specName];
        const volume = calcAudioVolume(item, specCategory);
        if (specCategory === "music") {
            music[specName].volume = volume;
        } else {
            item.volume = volume;
        }
        return volume;
    } else {
        for (let category in audio) {
            if (category === "settings") continue;
            for (const name in audio[category]) {
                const item = audio[category][name];
                const volume = calcAudioVolume(item, category);

                if (category === "music") {
                    music[name].volume = volume;
                } else {
                    item.volume = volume;
                }
            }
        }
        if (game.map?.cars) setSpritesheet();
    }
}

function calcAudioVolume(item, category) {
    const {audio} = global;
    if (category === "item") category = "weapon";

    const percent = (audio.settings[category] - 50) * 2;
    const rangeToMax = 1 - item.maxVolume;
    const rangeToMin = item.maxVolume;

    const percentVolume = ((percent >= 0 ? rangeToMax : rangeToMin) * percent) / 100;
    let volume = item.maxVolume + percentVolume;
    if (volume > 1) volume = 1;
    if (volume < 0) volume = 0;

    return volume;
}

//############################################################################# Music

let musicInterval = {
    lobby: null,
    game: null
}

function startMusic(name) {
    const {music} = global;
    if (!music[name].paused) return;

    const savedVol = setAudioVolume("music", name);
    let currentVol = 0;
    music[name].volume = 0;
    music[name].play();

    clearInterval(musicInterval[name]);
    musicInterval[name] = setInterval(() => {
        currentVol += (savedVol * 10) / 100;
        if (currentVol < 1 && currentVol < savedVol) {
            music[name].volume = currentVol;
        } else {
            music[name].volume = savedVol;
            clearInterval(musicInterval[name]);
        }
    }, 100);
}

function stopMusic(name, pause = false) {
    const {music} = global;
    if (music[name].paused) return;

    const savedVol = setAudioVolume("music", name);
    let currentVol = music[name].volume;

    clearInterval(musicInterval[name]);
    musicInterval[name] = setInterval(() => {
        currentVol -= (savedVol * 10) / 100;
        if (currentVol > 0) {
            music[name].volume = currentVol;
        } else {
            music[name].volume = 0;
            music[name].pause();
            if (!pause) music[name].currentTime = 0;
            clearInterval(musicInterval[name]);
        }
    }, 100);
}

//############################################################################# Fullscreen

function enterFullscreen() {
    const element = document.documentElement;
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
    } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
    }
}
    
function exitFullscreen() {
    if (isFullscreen()) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }
}

function isFullscreen() {
    return document.fullscreenElement !== null;
}

//############################################################################# Export

export {round, random, px2pct, pct2px, showModal, hideModal, createAudio, setAudioVolume, startMusic, stopMusic, enterFullscreen, exitFullscreen, isFullscreen};



//############################################################################# Proxy (Observable)

// const lobby = new Proxy({
//     player: []
// }, proxy);

// const proxy = {
//     get(target, key) {
//         if (typeof target[key] === 'object' && target[key] !== null) {
//           return new Proxy(target[key], validator)
//         } else {
//           return target[key];
//         }
//         // if (key === "isProxy") return true;
//         // const prop = target[key];
//         // if (typeof prop === "undefined") return;
//         // if (!prop.isProxy && typeof prop === "object") target[key] = new Proxy(prop, proxy);
//         // return target[key];
//     },
//     set(target, key, value) {
//         console.log(target, key, value);
//         // if (key === "health") {}
//         target[key] = value;
//         return true;
//     }
// }