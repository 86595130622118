//############################################################################# Import

import {round, showModal, hideModal, startMusic, stopMusic} from "./functions";
import {renderCanvas, renderMap} from "./render";

import global from "../components/Global";

//############################################################################# Game Frame

function gameFrame(currentFrameTime = 0) {
    const {frame, game, local} = global;
    frame.interval = currentFrameTime - frame.lastTime;
    frame.lastTime = currentFrameTime;
    
    renderCanvas();

    if (game.status === "running") {
        requestAnimationFrame(gameFrame);
    } else {
        for (const playerId in local.player) {
            if (local.player[playerId].audio) local.player[playerId].audio.pause();
        }
        for (const carId in local.car) {
            if (local.car[carId].audio) local.car[carId].audio.pause();
        }
    }
}

//############################################################################# Game Status

function startGame() {
    gameFrame(0);
    hideModal("lobby");
    hideModal("pause");
    hideModal("map");
    hideModal("fullscreen");
    stopMusic("lobby");
    startMusic("game");
}

function pauseGame() {
    showModal("pause");
}

function resetGame() {
    const {local} = global;
    local.effect.weapon = {};
    hideModal("pause");
    hideModal("evaluation");
    showModal("lobby");
    stopMusic("game");
    startMusic("lobby");
}

//############################################################################# Set Field Size

function initField() {
    const {game, field} = global;
    const screen = {
        width: window.innerWidth,
        height: window.innerHeight
    }
    //------------------------------------------------------ Field
    const blockX = round((screen.width * 0.98) / game.map.size[0]);
    const blockY = round((screen.height * 0.90) / game.map.size[1]);
    field.block = (blockY < blockX) ? blockY : blockX;
    field.width = field.block * game.map.size[0];
    field.height = field.block * game.map.size[1];
    //------------------------------------------------------ Field Grid
    field.grid = [];
    field.gridCoords = {x: [], y: []};
    for (let j = 0; j < game.map.size[1]; j++) {
        for (let i = 0; i < game.map.size[0]; i++) {
            field.grid.push({
                type: "street",
                texture: null,
                coords: [field.block * i, (field.block * i) + field.block, field.block * j, (field.block * j) + field.block],
                index: field.grid.length
            });
            field.gridCoords.x.push((field.block * i) + (field.block / 2));
            field.gridCoords.y.push((field.block * j) + (field.block / 2));
        }
    }
    //------------------------------------------------------ Building
    for (const building of game.map.buildings) {
        const buildingCoords = [
            building.pos.x * field.block,
            (building.pos.x * field.block) + (building.width * field.block),
            building.pos.y * field.block,
            (building.pos.y * field.block) + (building.height * field.block)
        ];
        for (let block of field.grid) {
            if (
                block.coords[0] >= buildingCoords[0] &&
                block.coords[1] <= buildingCoords[1] &&
                block.coords[2] >= buildingCoords[2] &&
                block.coords[3] <= buildingCoords[3]
                ) block.type = "building";
        }
    }
    global.streetBlocks = field.grid.filter(x => x.type === "street").length;
    //------------------------------------------------------ Texture
    buildingTexturesUsed = [];
    lastDrawedBuilding = null;
    for (let i = 0; i < field.grid.length; i++) {
        const block = field.grid[i];
        if (block.type === "building") {
            if (block.texture) continue;
            setBuildingTexture(i);
        }
        if (block.type === "street") setStreetTexture(i);
    }
    //------------------------------------------------------ Render Map
    setTimeout(renderMap, 1);
}

//############################################################################# Set Building Texture

let buildingTexturesUsed = [];
let lastDrawedBuilding = null;

function setBuildingTexture(i) {
    const {field, game, texture} = global;

    const buildingMaxLength = 8;
    const adjacentBlock = getAdjacentBlocks(i, buildingMaxLength);
    let direction = "x";
    let maxDrawX = 1;
    let maxDrawY = 1;
    //------------------------------------------------------ Max Draw
    for (let j = 0; j < buildingMaxLength; j++) {
        if (adjacentBlock[j].right?.type !== "building" || adjacentBlock[j].right?.texture !== null) break;
        maxDrawX ++;
    }
    for (let j = 0; j < buildingMaxLength; j++) {
        if (adjacentBlock[j].down?.type !== "building" || adjacentBlock[j].down?.texture !== null) break;
        maxDrawY ++;
    }
    // if (random(1, 2) === 2) direction = "y";
    if (maxDrawY > maxDrawX || maxDrawX <= 2) direction = "y";
    //------------------------------------------------------ All Textures
    const buildingTextures = Object.keys(texture.building[direction]).reverse().map(x => {
        const regex = new RegExp(/(\d+)x(\d+)_(\d+)/);
        return {
            name: x,
            width: Number(x.replace(regex, (direction === "x") ? "$1" : "$2")),
            height: Number(x.replace(regex, (direction === "x") ? "$2" : "$1")),
            number: Number(x.replace(regex, "$3"))
        };
    });
    //------------------------------------------------------ Fitting Textures
    const ref = (direction === "x") ? "height" : "width";
    const fittingTexturesAll = buildingTextures.filter(x => x.width <= maxDrawX && x.height <= maxDrawY);
    const fittingTextures = {
        all: fittingTexturesAll,
        unused: fittingTexturesAll.filter(x => !buildingTexturesUsed.includes(x.name)),
        big: {
            all: fittingTexturesAll.filter(x => x[ref] >= 2),
            unused: fittingTexturesAll.filter(x => x[ref] >= 2 && !buildingTexturesUsed.includes(x.name))
        },
        small: {
            all: fittingTexturesAll.filter(x => x[ref] <= 1),
            unused: fittingTexturesAll.filter(x => x[ref] <= 1 && !buildingTexturesUsed.includes(x.name))
        }
    }
    //------------------------------------------------------ Choose Building Texture
    let textures = fittingTextures.all;
    if (fittingTextures.small.all.length > 0) textures = fittingTextures.small.all;
    if (fittingTextures.small.unused.length > 0) textures = fittingTextures.small.unused;
    if (fittingTextures.big.all.length > 0) textures = fittingTextures.big.all;
    if (fittingTextures.big.unused.length > 0) textures = fittingTextures.big.unused;

    // let index = random(0, textures.length - 1);
    let index = 0;
    if (lastDrawedBuilding === textures[index].name && textures.length > 1) index = 1;
    let {name, width, height} = textures[index];
    
    if (!buildingTexturesUsed.includes(name)) buildingTexturesUsed.push(name);
    //------------------------------------------------------ Draw Building
    field.grid[i].texture = {
        image: texture.building[direction][name],
        name,
        width,
        height
    }
    lastDrawedBuilding = name;
    //------------------------------------------------------ Set other blocks as occupied building
    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
            const blockIndex = i + x + (game.map.size[0] * y);
            if (!field.grid[blockIndex] || field.grid[blockIndex].texture) continue;
            field.grid[blockIndex].texture = true;
        }
    }
}

//############################################################################# Set Street Texture

function setStreetTexture(i) {
    const {field, game, texture} = global;

    const block = field.grid[i];
    const entryColIterator = Math.floor(i / game.map.size[0]);
    const block_left = (i === game.map.size[0] * entryColIterator) ? {type: (game.map.entries.col.includes(entryColIterator)) ? "street" : "building"} : field.grid[i - 1];
    const block_right = (i === game.map.size[0] * entryColIterator + game.map.size[0] - 1) ? {type: (game.map.entries.col.includes(entryColIterator + game.map.size[1])) ? "street" : "building"} : field.grid[i + 1];
    const block_up = field.grid[i - game.map.size[0]] || {type: (i < game.map.size[0] + 1 && game.map.entries.row.includes(i)) ? "street" : "building"};
    const block_down = field.grid[i + game.map.size[0]] || {type: (i > game.map.size[0] * (game.map.size[1] - 1) - 1 && game.map.entries.row.includes(i - (game.map.size[0] * (game.map.size[1] - 1)) + game.map.size[0])) ? "street" : "building"};

    let imgName = "horizontal";

    if (block_up.type === "street" && block_right.type === "building" && block_down.type === "street" && block_left.type === "building") imgName = "vertical";
    if (block_up.type === "street" && block_right.type === "street" && block_down.type === "street" && block_left.type === "street") imgName = "crossing";
    if (block_up.type === "street" && block_right.type === "building" && block_down.type === "street" && block_left.type === "street") imgName = "crossing_left";
    if (block_up.type === "street" && block_right.type === "street" && block_down.type === "street" && block_left.type === "building") imgName = "crossing_right";
    if (block_up.type === "street" && block_right.type === "street" && block_down.type === "building" && block_left.type === "street") imgName = "crossing_up";
    if (block_up.type === "building" && block_right.type === "street" && block_down.type === "street" && block_left.type === "street") imgName = "crossing_down";

    if (block_up.type === "street" && block_right.type === "street" && block_down.type === "building" && block_left.type === "building") imgName = "corner_bottom_left";
    if (block_up.type === "street" && block_right.type === "building" && block_down.type === "building" && block_left.type === "street") imgName = "corner_bottom_right";
    if (block_up.type === "building" && block_right.type === "street" && block_down.type === "street" && block_left.type === "building") imgName = "corner_top_left";
    if (block_up.type === "building" && block_right.type === "building" && block_down.type === "street" && block_left.type === "street") imgName = "corner_top_right";

    if (block_up.type === "building" && block_right.type === "building" && block_down.type === "street" && block_left.type === "building") imgName = "deadend_top";
    if (block_up.type === "street" && block_right.type === "building" && block_down.type === "building" && block_left.type === "building") imgName = "deadend_bottom";
    if (block_up.type === "building" && block_right.type === "street" && block_down.type === "building" && block_left.type === "building") imgName = "deadend_left";
    if (block_up.type === "building" && block_right.type === "building" && block_down.type === "building" && block_left.type === "street") imgName = "deadend_right";

    block.texture = texture.street[imgName];
    block.textureName = imgName;
}
// //############################################################################# Get Adjacent Blocks

function getAdjacentBlocks(blockIndex = 0, number = 1) {
    const {field, game} = global;

    const adjacentBlocks = [];
    const entryColIterator = Math.floor(blockIndex / game.map.size[0]);

    for (let i = 0; i < number; i++) {
        adjacentBlocks.push({
            up: field.grid[blockIndex - (game.map.size[0] * (i + 1))] || null,
            right: (blockIndex + i + 1 < (game.map.size[0] * entryColIterator) + game.map.size[0] && field.grid[(blockIndex + i) + 1]) || null,
            down: field.grid[blockIndex + (game.map.size[0] * (i + 1))] || null,
            left: (blockIndex - i > game.map.size[0] * entryColIterator && field.grid[(blockIndex - i) - 1]) || null
        });
    }
    return adjacentBlocks;
}

//############################################################################# Export

export {gameFrame, initField, startGame, pauseGame, resetGame};