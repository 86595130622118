import React, {Component} from "react";
import {observer} from "mobx-react";
import {socket} from "../js/online";
import {hideModal} from "../js/functions";

import global from "./Global";

@observer
class Evaluation extends Component {

    componentDidMount() {
        hideModal("evaluation");
    }

    render() {
        const {lobby, game, client} = global;

        return (
            <div id="evaluation-container" className="modal">
                <div className="content">
                    <h1>Spielende</h1>
                    <div className="flex-col">
                        <h2>{(game.winner.id && game.winner.id in lobby.player) ? lobby.player[game.winner.id].name : "Niemand"}</h2>
                        <p>hat das Spiel gewonnen</p>
                    </div>
                    <div className="flex-row">
                        <button disabled={!(client.id in lobby.player)} onClick={() => socket.emit("gameStatus", "lobby")}>
                            <span><i className="far fa-dice"></i>Zurück zur Lobby</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Evaluation;